import React, { useState } from "react";
import { FaBars } from "react-icons/fa"; // Hamburger icon from react-icons
import "../css/NavBar.css"; // Custom CSS for styling
import instaLogo from "../assets/instagram.png";
import youtube from "../assets/youtube.png";

const NavBar = () => {
  const [isMobile, setIsMobile] = useState(false);

  const toggleMenu = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className="navbar">
      <div className="navbar__left">
        <div className="logo-nav">
          <h3>Sanj - Basic Learning App</h3>
        </div>
      </div>
      <div className="navbar__right">
        <div className="hamburger" onClick={toggleMenu}>
          <FaBars />
        </div>
        <ul className={isMobile ? "navbar__list active" : "navbar__list"}>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/ContactUs">Contact Us</a>
          </li>
          <li>
            <img src={youtube} alt="App Logo" class="nav-icon" />
          </li>
          <li>
            <img src={instaLogo} alt="App Logo" class="nav-icon" />
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
