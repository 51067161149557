import React from "react";
import banner from "../assets/home_banner.png";
import abc from "../assets/ic_abc.png";
import appIcon from "../assets/app_icon.png";
import math from "../assets/ic_math.png";
import game from "../assets/ic_game.png";
import hindi from "../assets/ic_hindi.png";
import mango from "../assets/mango.png";

import "../css/Home.css";
import NavBar from "../utils/NavBar";
import { useNavigate } from "react-router-dom";
import Footer from "../utils/Footer";

export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <NavBar />
      <div className="main-container-h">
        <div className="banner-h">
          <div className="background-h"></div>
        </div>

        <div className="lower-container-h">
          <h1 className="main-heading-h" style={{ marginTop: "50px" }}>
            Discover our new learning method
          </h1>

          <div className="box-containt-h">
            <div className="box-home" onClick={() => navigate("Punjabi")}>
              <img className="icon" src={appIcon} alt="" />
              <h2>Punjabi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("Hindi")}>
              <img className="icon" src={hindi} alt="" />
              <h2>Hindi</h2>
            </div>
            <div className="box-home" onClick={() => navigate("English")}>
              <img className="icon" src={abc} alt="" />
              <h2>English</h2>
            </div>
            <div className="box-home" onClick={() => navigate("Math")}>
              <img className="icon" src={math} alt="" />
              <h2>Math</h2>
            </div>
            <div className="box-home">
              <img className="icon" src={game} alt="" />
              <h2>Games</h2>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}
