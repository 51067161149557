import React from "react";
import "../css/Footer.css";
import logo from "../assets/app_icon.png";

export default function Footer() {
  return (
    <div className="footer">
      <div class="footer-container">
        <div class="footer-left">
          <img src={logo} alt="App Logo" class="logo" />
          <p class="app-name">Sanj - Basic Learning App</p>
        </div>
        <div class="footer-right">
          <ul class="footer-links">
            <li>
              <a href="AboutUs">About Sanj</a>
            </li>
            <li>
              <a href="ContactUs">Contact Us</a>
            </li>
            <li>
              <a href="Privacy">Privacy Policy</a>
            </li>
            <li>
              <a href="Terms">Terms and Condition</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
