import React from "react";
import "../css/English.css";

import NavBar from "../utils/NavBar";

export default function ContactUs() {
  return (
    <>
      <NavBar />
      <div className="main-container">
        <p
          style={{
            paddingTop: 20,
            paddingBottom: 1000,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: "justify",
          }}
        >
          We welcome your feedback, questions and suggestions! if you have any
          inquiries or need assistance, please feel free to reach out to us via
          email at app92innovation@gmail.com. We strive to respond to all
          inquiries promptly and look forward to hearing from you!
        </p>
      </div>
    </>
  );
}
