import React, { useEffect, useState } from "react";
import "../css/Punjabi.css";
import NavBar from "../utils/NavBar";
import apple from "../assets/apple.png";
import mango from "../assets/mango.png";
import banana from "../assets/banana.png";
import orange from "../assets/orange.png";
import grapes from "../assets/graps.png";
import gauva from "../assets/guava.png";
import watermalon from "../assets/watermelon.png";
import pomgranate from "../assets/pomegranate.png";
import papaya from "../assets/papaya.png";
import dates from "../assets/dates.png";
import pineapple from "../assets/pineapple.png";
import kiwi from "../assets/kiwi.png";
import strawberry from "../assets/strawberry.png";

import potato from "../assets/potato.png";
import couliflower from "../assets/cauliflower.png";
import carrot from "../assets/carrot.png";
import peas from "../assets/peas.png";
import onion from "../assets/onion.png";
import redchillie from "../assets/red_chilli.png";
import tomato from "../assets/tomato.png";
import eggplant from "../assets/eggplant.png";
import capsicum from "../assets/capsicum.png";
import Footer from "../utils/Footer";

export default function Punjabi() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  const alphabet = Array.from("ੳਅੲਸਹਕਖਗਘਙਚਛਜਝਞਟਠਡਢਣਤਥਦਧਨਪਫਬਭਮਯਰਲਵੜ");
  const words = [
    "ਬਸ",
    "ਘਰ",
    "ਜਲ",
    "ਫਲ",
    "ਖਤ",
    "ਰਸ",
    "ਚਲ",
    "ਮਨ",
    "ਭਰ",
    "ਤਨ",
    "ਤਨ",
    "ਬਟਨ",
    "ਲਸਣ",
    "ਅਮਰ",
    "ਨਰਸ",
    "ਮਟਰ",
    "ਕਲਮ",
    "ਸੜਕ",
    "ਰਬੜ",
    "ਨਮਕ",
    "ਬਰਫ਼",
  ];
  const fruitsName = [
    { icon: apple, name: "ਸੇਬ" },
    { icon: mango, name: "ਅੰਬ" },
    { icon: banana, name: "ਕੇਲਾ" },
    { icon: orange, name: "ਸੰਤਰਾ" },
    { icon: grapes, name: "ਅੰਗੂਰ" },
    { icon: gauva, name: "ਅਮਰੂਦ" },
    { icon: watermalon, name: "ਤਰਬੂਜ" },
    { icon: pomgranate, name: "ਅਨਾਰ" },
    { icon: papaya, name: "ਪਪੀਤਾ" },
    { icon: dates, name: "ਖਜੂਰ" },
    { icon: pineapple, name: "ਅਨਾਨਾਸ" },
    { icon: kiwi, name: "ਕੀਵੀ" },
    { icon: strawberry, name: "ਸਟ੍ਰਾਬੈਰੀ" },
  ];
  const colorsName = [
    { name: "ਲਾਲ", color: "#FF0000" },
    { name: "ਨੀਲਾ", color: "#0000FF" },
    { name: "ਚਿੱਟਾ", color: "#ffffff" },
    { name: "ਪੀਲਾ", color: "#FFFF00" },
    { name: "ਹਰਾ", color: "#00FF00" },
    { name: "ਕਾਲਾ", color: "#000000" },
    { name: "ਜਾਮਨੀ", color: "#800080" },
    { name: "ਗੁਲਾਬੀ", color: "#FF6ECB" },
    { name: "ਸਲੇਟੀ", color: "#808080" },
    { name: "ਕੇਸਰੀ", color: "#FFA500" },
  ];

  const counting = [
    "ਇੱਕ",
    "ਦੋ",
    "ਤਿੰਨ",
    "ਚਾਰ",
    "ਪੰਜ",
    "ਛੇ",
    "ਸੱਤ",
    "ਅੱਠ",
    "ਨੌ",
    "ਦਸ",
    "ਗਿਆਰਾਂ",
    "ਬਾਰਾਂ",
    "ਤੇਰਾਂ",
    "ਚੌਦਾਂ",
    "ਪੰਦਰਾਂ",
    "ਸੋਲਾਂ",
    "ਸਤਾਰਾਂ",
    "ਅਠਾਰਾਂ",
    "ਉੱਨੀ",
    "ਵੀਹ",
    "ਇੱਕੀ",
    "ਬਾਈ",
    "ਤੇਈ",
    "ਚੌਬੀ",
    "ਪੱਚੀ",
    "ਛੱਬੀ",
    "ਸਤਾਈ",
    "ਅਠਾਈ",
    "ਉਨੱਤੀ",
    "ਤੀਹ",
    "ਇਕੱਤੀ",
    "ਬੱਤੀ",
    "ਤੇਤੀ",
    "ਚੌਂਤੀ",
    "ਪੈਂਤੀ",
    "ਛੱਤੀ",
    "ਸੈਂਤੀ",
    "ਅਠੱਤੀ",
    "ਉਨਤਾਲੀ",
    "ਚਾਲੀ",
    "ਇਕਤਾਲੀ",
    "ਬਿਆਲੀ",
    "ਤਰਤਾਈ",
    "ਚੁਤਾਲੀ",
    "ਪਨਤਾਲੀ",
    "ਛਿਆਲੀ",
    "ਸਨਤਾਲੀ",
    "ਅਠਤਾਲੀ",
    "ਉਨੰਜਾ",
    "ਪੰਜਾਹ",
  ];

  const monthsName = [
    "ਜਨਵਰੀ",
    "ਫ਼ਰਵਰੀ",
    "ਮਾਰਚ",
    "ਅਪ੍ਰੈਲ",
    "ਮਈ",
    "ਜੂਨ",
    "ਜੁਲਾਈ",
    "ਅਗਸਤ",
    "ਸਤੰਬਰ",
    "ਅਕਤੂਬਰ",
    "ਨਵੰਬਰ",
    "ਦਸੰਬਰ",
  ];

  const daysName = [
    "ਸੋਮਵਾਰ",
    "ਮੰਗਲਵਾਰ",
    "ਬੁੱਧਵਾਰ",
    "ਵੀਰਵਾਰ",
    "ਸ਼ੁੱਕਰਵਾਰ",
    "ਸ਼ਨਿੱਚਰਵਾਰ",
    "ਐਤਵਾਰ",
  ];
  const vegName = [
    { icon: potato, name: "ਆਲੂ" },
    { icon: couliflower, name: "ਫੁੱਲ ਗੋਭੀ" },
    { icon: carrot, name: "ਗਾਜ਼ਰ" },
    { icon: peas, name: "ਮਟਰ" },
    { icon: onion, name: "ਪਿਆਜ਼" },
    { icon: redchillie, name: "ਲਾਲ ਮਿਰਚ" },
    { icon: tomato, name: "ਟਮਾਟਰ" },
    { icon: eggplant, name: "ਬੈਂਗਣ" },
    { icon: capsicum, name: "ਸ਼ਿਮਲਾ ਮਿਰਚ" },
  ];
  const gurusName = [
    "ਗੁਰੂ ਨਾਨਕ ਦੇਵ ਜੀ",
    "ਗੁਰੂ ਅੰਗਦ ਦੇਵ ਜੀ",
    "ਗੁਰੂ ਅਮਰ ਦਾਸ ਜੀ",
    "ਗੁਰੂ ਰਾਮ ਦਾਸ ਜੀ",
    "ਗੁਰੂ ਅਰਜਨ ਦੇਵ ਜੀ",
    "ਗੁਰੂ ਹਰ ਗੋਬਿੰਦ ਸਿੰਘ ਜੀ",
    "ਗੁਰੂ ਹਰ ਰਾਏ ਜੀ",
    "ਗੁਰੂ ਹਰ ਕ੍ਰਿਸ਼ਨ ਜੀ",
    "ਗੁਰੂ ਤੇਗ ਬਹਾਦਰ ਜੀ",
    "ਗੁਰੂ ਗੋਬਿੰਦ ਸਿੰਘ ਜੀ",
  ];

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="uper-cont">
          <div className="box-containt-up">
            {/* <div className="box-up" onClick={ ()=> changeAlpha("A")}> */}
            <div className="box-up" onClick={() => handletab(1)}>
              <h3>ਵਰਣਮਾਲਾ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(2)}>
              <h3>ਸ਼ਬਦ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(3)}>
              <h3>ਫਲਾਂ ਦਾ ਨਾਮ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(4)}>
              <h3>ਰੰਗ ਦਾ ਨਾਮ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(5)}>
              <h3>ਗਿਣਤੀ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(6)}>
              <h3>ਮਹੀਨਿਆਂ ਦਾ ਨਾਮ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(7)}>
              <h3>ਦਿਨਾਂ ਦਾ ਨਾਮ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(8)}>
              <h3>ਸਬਜ਼ੀਆਂ ਦਾ ਨਾਮ</h3>
            </div>
            <div className="box-up" onClick={() => handletab(9)}>
              <h3>ਸਿੱਖ ਗੁਰੂ </h3>
            </div>
          </div>

          <div className="res-selection">
            <select name="selecttab" id="selecttab" onChange={handleselect}>
              <option value="default" disabled>
                Please select
              </option>
              <option value="1">Alphabets</option>
              <option value="2">Words</option>
              <option value="3">Fruits Name</option>
              <option value="4">Colours Name</option>
              <option value="5">Counting</option>
              <option value="6">Months Name</option>
              <option value="7">Days Name</option>
              <option value="8">Vegitable Name</option>
              <option value="9">Sikh Guru Names</option>
            </select>
          </div>
        </div>

        <div className="lower-container">
          <div className={showtab == 1 ? "box-containt" : "hide"}>
            {alphabet.map((letter) => (
              <div className="box-content">
                <div className="box">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 2 ? "box-containt" : "hide"}>
            {words.map((letter) => (
              <div className="box-content">
                <div className="box-guru">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 3 ? "box-containt" : "hide"}>
            {fruitsName.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <img className="alpa-img" src={letter.icon} alt="" />
                  <h2>{letter.name}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 4 ? "box-containt" : "hide"}>
            {colorsName.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <div
                    style={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: letter.color,
                      margin: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  ></div>
                  <h2>{letter.name}</h2>
                </div>
              </div>
            ))}
          </div>

          <div className={showtab == 5 ? "box-containt" : "hide"}>
            {counting.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 6 ? "box-containt" : "hide"}>
            {monthsName.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 7 ? "box-containt" : "hide"}>
            {daysName.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 8 ? "box-containt" : "hide"}>
            {vegName.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <img className="alpa-img" src={letter.icon} alt="" />
                  <h2>{letter.name}</h2>
                </div>
              </div>
            ))}
          </div>
          <div className={showtab == 9 ? "box-containt" : "hide"}>
            {gurusName.map((letter) => (
              <div className="box-content">
                <div className="box-guru">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
}
