import React, { useEffect, useState } from "react";
import "../css/English.css";
import Footer from "../utils/Footer";
import icApple from "../assets/ic_apple.png";
import ball from "../assets/ball.png";
import cat from "../assets/cat.png";
import dog from "../assets/dog.png";
import elephant from "../assets/elephant.png";
import fish from "../assets/fish.png";
import goat from "../assets/ic_goat.png";
import horse from "../assets/horse.png";
import ink from "../assets/ink.png";
import jug from "../assets/jug.png";
import kite from "../assets/kite.png";
import lion from "../assets/lion.png";
import monkey from "../assets/monkey.png";
import nest from "../assets/nest.png";
import owl from "../assets/owl.png";
import parrot from "../assets/parrot.png";
import queen from "../assets/queen.png";
import rat from "../assets/rat.png";
import ship from "../assets/ship.png";
import tiger from "../assets/tiger.png";
import umbrella from "../assets/umbrella.png";
import van from "../assets/van.png";
import watch from "../assets/watch.png";
import xmas from "../assets/tree.png";
import yak from "../assets/yak.png";
import zebra from "../assets/zebra.png";
import NavBar from "../utils/NavBar";

export default function Math() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  const counting = Array.from({ length: 100 }, (_, index) => index + 1);

  const alphabet = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
  const smallAlphabet = Array.from("abcdefghijklmnopqrstuvwxyz");
  const a2z = [
    { icon: icApple, name: "Apple" },
    { icon: ball, name: "Ball" },
    { icon: cat, name: "Cat" },
    { icon: dog, name: "Dog" },
    { icon: elephant, name: "Elephant" },
    { icon: fish, name: "Fish" },
    { icon: goat, name: "Goat" },
    { icon: horse, name: "Horse" },
    { icon: ink, name: "Ink" },
    { icon: jug, name: "Jug" },
    { icon: kite, name: "Kite" },
    { icon: lion, name: "Lion" },
    { icon: monkey, name: "Monky" },
    { icon: nest, name: "Nest" },
    { icon: owl, name: "Owl" },
    { icon: parrot, name: "Parrot" },
    { icon: queen, name: "Queen" },
    { icon: rat, name: "Rat" },
    { icon: ship, name: "Ship" },
    { icon: tiger, name: "Tiger" },
    { icon: umbrella, name: "Umbrella" },
    { icon: van, name: "Van" },
    { icon: watch, name: "Watch" },
    { icon: xmas, name: "Xmas" },
    { icon: yak, name: "Yak" },
    { icon: zebra, name: "Zebra" },
  ];

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="uper-cont">
          <div className="box-containt-up">
            <div className="box-up" onClick={() => handletab(1)}>
              <h3>Counting(1-100)</h3>
            </div>

            <div className="box-up" onClick={() => handletab(2)}>
              <h3>Table(2-20)</h3>
            </div>

            <div className="box-up" onClick={() => handletab(3)}>
              <h3>Addition (+)</h3>
            </div>
            <div className="box-up" onClick={() => handletab(3)}>
              <h3>Subtraction (-)</h3>
            </div>
            <div className="box-up" onClick={() => handletab(3)}>
              <h3>Multiplication (x)</h3>
            </div>
            <div className="box-up" onClick={() => handletab(3)}>
              <h3>Division (÷)</h3>
            </div>
          </div>

          <div className="res-selection">
            <select name="selecttab" id="selecttab" onChange={handleselect}>
              <option value="default" disabled>
                Please select
              </option>
              <option value="1">Counting(1-100)</option>
              <option value="2">Table(2-20)</option>
              <option value="3">Addition (+)</option>
              <option value="3">Subtraction (-)</option>
              <option value="3">Multiplication (x)</option>
              <option value="3">Division (÷)</option>
            </select>
          </div>
        </div>

        <div className="lower-container">
          <div className={showtab == 1 ? "box-containt" : "hide"}>
            {counting.map((letter) => (
              <div className="box-content">
                <div className="box">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
