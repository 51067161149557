import React, { useState } from "react";
import "../css/English.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";

export default function Terms() {
  return (
    <>
      <NavBar />
      <div className="main-container">
        <p></p>
      </div>
    </>
  );
}
