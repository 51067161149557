import React, { useState } from "react";
import "../css/English.css";
import Footer from "../utils/Footer";

import NavBar from "../utils/NavBar";

export default function AboutUs() {
  return (
    <>
      <NavBar />
      <div className="main-container">
        <p
          style={{
            paddingTop: 20,
            paddingBottom: 1000,
            paddingLeft: 20,
            paddingRight: 20,
            textAlign: "justify",
          }}
        >
          Sanj is an engaging and interative learning platform crafted to
          introduce children to the fundamentals of punjabi and English
          languages. With vibrant visuals, immersive activities, and dynamic
          lessons, the app transforms languages learning into an enjoyable and
          impactful experience for kids of all ages. Our current focus it to
          offer an intuitive and accessible way for children to grasp these
          languages, with plans to expand and enhance the content in the near
          future.
        </p>
      </div>
    </>
  );
}
