import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import English from './screens/English'
import Punjabi from './screens/Punjabi';
import Hindi from './screens/Hindi'
import Scrolltotop from './utils/Scrolltotop';
import Math from './screens/Math';
import ContactUs from './screens/ContactUs';
import AboutUs from './screens/AboutUs';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Scrolltotop />
        <Routes>
          <Route element={<Home to="/" />} path="/" />
          <Route path="/English" element={<English />} />
          <Route path="/Punjabi" element={<Punjabi />} />
          <Route path="/Hindi" element={<Hindi />} />
          <Route path="/Math" element={<Math />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Terms" element={<Terms />} />
          <Route path="/Privacy" element={<Privacy />} />



        </Routes>

      </BrowserRouter>

    </div>
  );
}

export default App;