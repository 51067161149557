import React, { useEffect, useState } from "react";
import "../css/Hindi.css";
import icApple from "../assets/ic_apple.png";
import ball from "../assets/ball.png";
import cat from "../assets/cat.png";
import dog from "../assets/dog.png";
import elephant from "../assets/elephant.png";
import fish from "../assets/fish.png";
import goat from "../assets/ic_goat.png";
import horse from "../assets/horse.png";
import ink from "../assets/ink.png";
import jug from "../assets/jug.png";
import kite from "../assets/kite.png";
import lion from "../assets/lion.png";
import monkey from "../assets/monkey.png";
import nest from "../assets/nest.png";
import owl from "../assets/owl.png";
import parrot from "../assets/parrot.png";
import queen from "../assets/queen.png";
import rat from "../assets/rat.png";
import ship from "../assets/ship.png";
import tiger from "../assets/tiger.png";
import umbrella from "../assets/umbrella.png";
import van from "../assets/van.png";
import watch from "../assets/watch.png";
import xmas from "../assets/tree.png";
import yak from "../assets/yak.png";
import zebra from "../assets/zebra.png";
import NavBar from "../utils/NavBar";

import pomgranate from "../assets/pomegranate.png";
import mango from "../assets/mango.png";
import imali from "../assets/ic_imali.png";
import ikh from "../assets/ic_scane.png";
import camel from "../assets/ic_camel.png";
import rishi from "../assets/ic_rishi.png";
import foot from "../assets/ic_foot.png";
import ainak from "../assets/ic_ainak.png";
import ukhali from "../assets/ic_aukhli.png";
import women from "../assets/ic_women.png";
import pigon from "../assets/ic_pigon.png";
import rabit from "../assets/ic_rabbit.png";
import pot from "../assets/ic_pot.png";
import spoon from "../assets/ic_spoon.png";
import jhula from "../assets/ic_swing.png";
import tomato from "../assets/tomato.png";

import th from "../assets/ic_th.png";
import drum from "../assets/ic_drum.png";
import dhol from "../assets/ic_dhol.png";
import thrmos from "../assets/ic_thermos.png";
import door from "../assets/ic_door.png";
import bow from "../assets/ic_bow.png";
import tap from "../assets/ic_nal.png";
import flower from "../assets/ic_flower.png";
import bear from "../assets/ic_bear.png";
import fire from "../assets/ic_fire.png";
import rope from "../assets/ic_rope.png";
import girl from "../assets/ic_girl.png";
import tree2 from "../assets/ic_tree.png";
import shh from "../assets/ic_hexa.png";
import sun from "../assets/ic_sun.png";
import worrier from "../assets/ic_warrior.png";
import tir from "../assets/ic_tri.png";

import banana from "../assets/banana.png";
import orange from "../assets/orange.png";
import grapes from "../assets/graps.png";
import gauva from "../assets/guava.png";
import watermalon from "../assets/watermelon.png";
import papaya from "../assets/papaya.png";
import dates from "../assets/dates.png";
import pineapple from "../assets/pineapple.png";
import kiwi from "../assets/kiwi.png";
import strawberry from "../assets/strawberry.png";
export default function English() {
  const [showtab, setshowtab] = useState(1);

  const handletab = (e) => {
    setshowtab(e);
  };

  const handleselect = (e) => {
    setshowtab(e.target.value);
  };

  const alphabet = [
    "क",
    "ख",
    "ग",
    "घ",
    "ङ",
    "च",
    "छ",
    "ज",
    "झ",
    "ञ",
    "ट",
    "ठ",
    "ड",
    "ढ",
    "ण",
    "त",
    "थ",
    "द",
    "ध",
    "न",
    "प",
    "फ",
    "ब",
    "भ",
    "म",
    "य",
    "र",
    "ल",
    "व",
    "श",
    "ष",
    "स",
    "ह",
    "ळ",
    "क्ष",
    "त्र",
    "ज्ञ",
  ];

  const smallAlphabet = [
    "अ",
    "आ",
    "इ",
    "ई",
    "उ",
    "ऊ",
    "ऋ",
    "ॠ",
    "ऌ",
    "ॡ",
    "ए",
    "ऐ",
    "ओ",
    "औ",
    "अं",
    "अः",
  ];

  const a2z = [
    { icon: pomgranate, name: "अ" },
    { icon: mango, name: "आ" },
    { icon: ikh, name: "इ" },
    { icon: imali, name: "ई" },
    { icon: owl, name: "उ" },
    { icon: camel, name: "ऊ" },
    { icon: rishi, name: "ऋ" },
    { icon: foot, name: "ए" },
    { icon: ainak, name: "ऐ" },
    { icon: ukhali, name: "ओ" },
    { icon: women, name: "औ" },
    { icon: grapes, name: "अं" },
    { icon: monkey, name: "अः" },
    { icon: pigon, name: "क" },
    { icon: rabit, name: "ख" },
    { icon: pot, name: "ग" },
    { icon: watch, name: "घ" },
    { icon: "", name: "ङ" },
    { icon: spoon, name: "च" },
    { icon: umbrella, name: "छ" },
    { icon: jug, name: "ज" },
    { icon: jhula, name: "झ" },
    { icon: watch, name: "ञ" },
    { icon: tomato, name: "ट" },
    { icon: th, name: "ठ" },
    { icon: drum, name: "ड" },
    { icon: dhol, name: "ढ" },
    { icon: "", name: "ण" },
    { icon: watermalon, name: "त" },
    { icon: thrmos, name: "थ" },
    { icon: door, name: "द" },
    { icon: bow, name: "ध" },
    { icon: tap, name: "न" },
    { icon: kite, name: "प" },
    { icon: flower, name: "फ" },
    { icon: goat, name: "ब" },
    { icon: bear, name: "भ" },
    { icon: fish, name: "म" },
    { icon: fire, name: "य" },
    { icon: rope, name: "र" },
    { icon: girl, name: "ल" },
    { icon: tree2, name: "व" },
    { icon: tiger, name: "श" },
    { icon: shh, name: "ष" },
    { icon: sun, name: "स" },
    { icon: elephant, name: "ह" },
    { icon: worrier, name: "क्ष" },
    { icon: tir, name: "त्र" },
    { icon: "", name: "ज्ञ" },
  ];

  return (
    <>
      <NavBar />
      <div className="main-container">
        <div className="uper-cont">
          <div className="box-containt-up">
            {/* <div className="box-up" onClick={ ()=> changeAlpha("A")}> */}
            <div className="box-up" onClick={() => handletab(1)}>
              <h3>वर्णमाला</h3>
            </div>

            <div className="box-up" onClick={() => handletab(2)}>
              <h3>स्वर</h3>
            </div>

            <div className="box-up" onClick={() => handletab(3)}>
              <h3>अ से अनार</h3>
            </div>
          </div>

          <div className="res-selection">
            <select name="selecttab" id="selecttab" onChange={handleselect}>
              <option value="default" disabled>
                Please select
              </option>
              <option value="1">वर्णमाला</option>
              <option value="2">स्वर</option>
              <option value="3">अ से अनार</option>
            </select>
          </div>
        </div>

        <div className="lower-container">
          <div className={showtab == 1 ? "box-containt" : "hide"}>
            {alphabet.map((letter) => (
              <div className="box-content">
                <div className="box">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>

          <div className={showtab == 2 ? "box-containt" : "hide"}>
            {smallAlphabet.map((letter) => (
              <div className="box-content">
                <div className="box">
                  <h2>{letter}</h2>
                </div>
              </div>
            ))}
          </div>

          <div className={showtab == 3 ? "box-containt" : "hide"}>
            {a2z.map((letter) => (
              <div className="box-content">
                <div className="box-alpa-img">
                  <img className="alpa-img" src={letter.icon} alt="" />
                  <h2>{letter.name}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
